import React, { useContext } from "react"
import ContentStationDetails from "../components/ContentStationDetails"
import { AppContext } from "../contexts/AppContext"

/**
 * All the station details page are generated using StationDetails templete during build time. 
 * Index file generated by this template is used in execute.js to render page for new 
 * stations added after previous gatsby build
 * @param {*} props 
 */

const StationDetails = props => {
  let { selectedCountry } = useContext(AppContext)
  return selectedCountry ? (
    <ContentStationDetails {...props.pageContext} location={props.location} />
  ) : null
}

export default StationDetails